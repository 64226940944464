export function validatefirstname (rule, value, callback) {
  if (value === '') {
    callback(new Error('Please enter firstname.'))
  } else {
    callback()
  }
}
export function validatelastname (rule, value, callback) {
  if (value === '') {
    callback(new Error('Please enter lastname.'))
  } else {
    callback()
  }
}

// 验证邮箱
const reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
export function validateEmail(rule, value, callback) {
  if (value === '') {
    callback(new Error('Please enter email.'))
  } else if (!reg.test(value)) {
    callback(new Error('Email account error.'))
  } else {
    callback()
  }
}

// 验证密码
const exp = /^(?![^a-zA-Z]+$)(?!\D+$).{6,16}$/
export function validatePwd(rule, value, callback) {
  if (value === '') {
    callback(new Error('Please enter password.'))
  } else if (!exp.test(value)) {
    callback(new Error('6~16 chars,mletters&numbers.'))
  } else {
    callback()
  }
}

// 验证新密码
export function validateNewpass(v) {
  return function(rule, value, callback) {
    if (value === '') {
      callback(new Error('Please enter your new password.'))
    } else if (!exp.test(value)) {
      callback(new Error('6~16 chars,mletters&numbers'))
    } else {
      if (v.PassForm.checkpass !== '') {
        v.$refs.PassForm.validateField('checkpass')
      }
      callback()
    }
  }
}

// 验证确认密码
export function validateCheckpass(o) {
  return function(rule, value, callback) {
    if (value === '') {
      callback(new Error('Please enter your new password again.'))
    } else if (value !== o.PassForm.newpass) {
      callback(new Error('The two new password entered do not match.'))
    } else {
      callback()
    }
  }
}

// 验证仓库名称
const shelf = /^[0-9a-zA-Z_-]{1,20}$/
export function validateShelfname(rule, value, callback) {
  if (value === '') {
    callback(new Error('Please enter shelf name.'))
  } else if (!shelf.test(value)) {
    callback(new Error('0-20 letters, numbers, underlines&middle lines.'))
  } else {
    callback()
  }
}
