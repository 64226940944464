<template>
  <div class="main-cont">
    <div class="loginbox"></div>
    <div class="logininner">
      <!-- 标题 -->
      <h3>Forgot Password</h3>
      <!-- 表单 -->
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        size="mini"
      >
        <el-form-item prop="email">
          <el-input
            type="email"
            v-model="ruleForm.email"
            autocomplete="off"
            prefix-icon="iconfont icon-024weiduxinxi"
          ></el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-input
            type="text"
            v-model="ruleForm.code"
            autocomplete="off"
            prefix-icon="iconfont icon-icon_verify"
            class="code"
          ></el-input>
        </el-form-item>
        <el-form-item class="getcode"
          ><el-button round @click="getcode" class="codebtn">{{
            timebtn.text
          }}</el-button></el-form-item
        >
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            autocomplete="off"
            prefix-icon="iconfont icon-mima"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >Submit</el-button
          >
          <el-button @click="resetForm('ruleForm')">Reset</el-button>
          <span class="relogin">
            <router-link to="/login">Back to login?</router-link>
          </span>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { validateEmail, validatePwd } from '@/utils/validate.js'
import { code, findpassword } from '@/api/user.js'
export default {
  data() {
    var validatCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter code'))
      } else if (!/^\d{6}$/.test(value)) {
        callback(new Error('Email code format error.'))
      } else {
        callback()
      }
    }
    return {
      timebtn: {
        text: 'Get Code',
        status: false,
        codeTime: 60
      },
      timerId: null,
      ruleForm: {
        code: '',
        email: '',
        password: ''
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'blur' }],
        code: [{ validator: validatCode, trigger: 'blur' }],
        password: [{ validator: validatePwd, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 获取验证码
    async getcode() {
      const data = await code({
        regitstered: 1,
        email: this.ruleForm.email
      })
      // console.log(data)
      if (data.status !== 201) {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
        return
      }
      this.timebtn.status = true
      this.timerId = setInterval(() => {
        var codeTime = this.timebtn.codeTime
        codeTime--
        this.timebtn.codeTime = codeTime
        this.timebtn.text = codeTime + 'S'
        if (this.timebtn.codeTime < 1) {
          clearInterval(this.timerId)
          this.timebtn.text = 'Recapture'
          this.timebtn.codeTime = 60
          this.timebtn.status = false
        }
      }, 1000)
    },
    // 提交找回密码
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = await findpassword({
            email: this.ruleForm.email,
            code: this.ruleForm.code,
            password: this.ruleForm.password
          })
          // console.log(data)
          if (data.status === 200) {
            this.$message({
              message: 'Operation successful, please login.',
              type: 'success'
            })
            this.$router.push('/login')
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
          }
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.main-cont {
  height: 100%;
}
.title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 20px;
}
.code {
  width: 70%;
}
.el-form {
  position: relative;
}
.getcode {
  position: absolute;
  width: 25%;
  top: 45px;
  right: 0;
  .codebtn {
    width: 100%;
  }
}
.loginbox {
  height: 100%;
  width: 100%;
  background: url(../../assets/images/02.jpg) center center no-repeat;
  background-size: 100% 100%;
  filter: blur(5px);
}
.logininner {
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  h3 {
    text-align: center;
    padding: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #2d3a4b;
  }
  /deep/.el-input {
    input {
      background: #fff;
      color: #333;
    }
  }
}

/deep/.el-form-item__content {
  margin-left: 0 !important;
}
.relogin {
  position: absolute;
  right: 5px;
  bottom: -5px;
  a {
    color: #409eff;
    text-decoration: underline;
    font-size: 12px;
  }
}
</style>
